import { FC, useState } from 'react'

import { faker } from '@faker-js/faker'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'
import { Bar } from 'react-chartjs-2'
import Select from 'react-select'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import {
  generateDayLabels,
  generateMonthLabels,
  generateQuarterLabels,
} from 'src/Util'

export const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#fff',
      titleColor: '#7C8DB5',
      bodyColor: '#000',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      ticks: { color: '#7C8DB5', beginAtZero: true },
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: { color: '#7C8DB5', beginAtZero: true },
    },
  },
}

const generateTimeFrameLabels = () => ({
  DAY: generateDayLabels(),
  MONTH: generateMonthLabels(), // Replace with your desired start month and year, and number of months
  QUARTER: generateQuarterLabels(),
})

const timeFrames = [
  { label: 'Monthly', value: 'MONTH' },
  { label: 'Quarterly', value: 'QUARTER' },
  { label: 'Daily', value: 'DAY' },
]

const filterByTimeFrameAndStatus = (
  selectedTimeFrame,
  attempts,
  TimeFrameLabels,
) => {
  const timeFrameData = {}

  for (const temporal of TimeFrameLabels[selectedTimeFrame]) {
    timeFrameData[temporal] = {
      inProgress: 0,
      completed: 0,
      inProgressNames: [],
      completedNames: [],
    }
  }

  for (const attempt of attempts) {
    let attemptStart = ''
    switch (selectedTimeFrame) {
      case 'DAY':
        attemptStart = dayjs(attempt.relatedTracking.createdAt).format('ddd')

        break
      case 'QUARTER':
        attemptStart =
          'Q' +
          dayjs(attempt.relatedTracking.createdAt).quarter() +
          ' ' +
          dayjs(attempt.relatedTracking.createdAt).format('YYYY')

        break
      case 'MONTH':
      default:
        attemptStart = dayjs(attempt.relatedTracking.createdAt).format(
          'MMM YYYY',
        )
    }
    const attemptStatus = attempt.status

    if (Object.prototype.hasOwnProperty.call(timeFrameData, attemptStart)) {
      if (attemptStatus === 'COMPLETED') {
        timeFrameData[attemptStart].completed++
        timeFrameData[attemptStart].completedNames.push(
          attempt.relatedTracking.memberName,
        )
      } else if (attemptStatus === 'IN_PROGRESS') {
        timeFrameData[attemptStart].inProgress++
        timeFrameData[attemptStart].inProgressNames.push(
          attempt.relatedTracking.memberName,
        )
      }
    }
  }

  return timeFrameData
}

const enrichDataWithMemberNames = (attempts, memberMap) => {
  return attempts.map((item) => {
    if (item.relatedTracking && item.relatedTracking.membershipId) {
      const memberId = item.relatedTracking.membershipId
      const memberName = memberMap[memberId]
      if (memberName) {
        return {
          ...item,
          relatedTracking: {
            ...item.relatedTracking,
            memberName: memberName,
          },
        }
      }
    }
    return item
  })
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const EngagementGraph: FC<{ currentCourse: any; memberships: any }> = ({
  currentCourse,
  memberships,
}) => {
  const [selectedTimeFrame, setTimeFrame] = useState('MONTH')
  const { trackEvent } = useAnalytics()

  let comparisonTime: any

  switch (selectedTimeFrame) {
    case 'DAY':
      comparisonTime = dayjs()
        .subtract(7, 'days')
        .startOf('day')
        .subtract(1, 'day')
        .startOf('week')
      break
    case 'QUARTER':
      comparisonTime = dayjs().subtract(1, 'year').startOf('quarter')
      break
    case 'MONTH':
    default:
      comparisonTime = dayjs().subtract(1, 'year').startOf('month')
  }

  const memberMap = memberships.reduce((acc, member) => {
    acc[member.id] = member.user.name
    return acc
  }, {})

  const attempts = currentCourse.reporting.attempts.filter((attempt) =>
    comparisonTime.isBefore(attempt.relatedTracking.createdAt),
  )

  const enrichedAttempts = enrichDataWithMemberNames(attempts, memberMap)

  const TimeFrameLabels = generateTimeFrameLabels()

  const processedData = filterByTimeFrameAndStatus(
    selectedTimeFrame,
    enrichedAttempts,
    TimeFrameLabels,
  )

  const completedLearners =
    TimeFrameLabels[selectedTimeFrame].map(
      (label) => processedData[label].completed || 0,
    ) ||
    TimeFrameLabels[selectedTimeFrame].map(() =>
      faker.number.int({ min: 0, max: 1000 }),
    )

  const inProgressLearners =
    TimeFrameLabels[selectedTimeFrame].map(
      (label) => processedData[label].inProgress || 0,
    ) ||
    TimeFrameLabels[selectedTimeFrame].map(() =>
      faker.number.int({ min: 0, max: 1000 }),
    )

  const data = {
    labels: TimeFrameLabels[selectedTimeFrame],
    datasets: [
      {
        label: 'Completed:',
        data: completedLearners,
        backgroundColor: '#34D399',
      },
      {
        label: 'Participated',
        data: inProgressLearners,
        backgroundColor: '#3B82F6',
      },
    ],
  }

  return (
    <div className="hidden flex-1 p-6 xl:block">
      <div className="flex grid h-full flex-col place-items-center rounded-lg bg-gray-100 p-6">
        <div className="mb-6 flex h-[30px] w-full">
          <div className="mr-auto text-lg font-bold">Learner Insight</div>
          <div className="mr-5 flex">
            <div className="my-auto mr-2 h-2 w-2 rounded bg-blue-400" />
            <div className="my-auto text-xs">First Participated</div>
          </div>
          <div className="mr-5 flex">
            <div className="my-auto mr-2 h-2 w-2 rounded bg-green-400" />
            <div className="my-auto text-xs">Completed</div>
          </div>
          <Select
            className="h-[30px] rounded bg-white shadow"
            options={timeFrames}
            placeholder="Select Time Frame"
            value={timeFrames.find(
              (timeFrame) => timeFrame.value === selectedTimeFrame,
            )}
            onChange={(selected) => {
              setTimeFrame(selected.value)
              trackEvent('Course Reporting', 'Engagement graph select time', {
                selectedTimeFrame: selected.value,
              })
            }}
          />
        </div>
        <Bar options={options} data={data} />
      </div>
    </div>
  )
}

export default EngagementGraph
