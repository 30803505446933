import { useEffect, useState } from 'react'

import {
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  PuzzlePieceIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { type LearnerAttemptTrackingStatus } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel/CircularProgressWithLabel'
import { default as Placeholder } from 'src/components/Empty/Empty'
import CourseChapterCard from 'src/components/Learner/Library/CourseChapterCard/CourseChapterCard'
import Button from 'src/components/Library/Button/Button'
import Modal from 'src/components/Modal/Modal'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

const RESET_LEARNER_COURSE_TRACKING = gql`
  mutation ResetLearnerCourseTrackingMutation($id: Int!) {
    resetLearnerCourseTracking(id: $id) {
      id
    }
  }
`

const LearnerSummary = ({
  course,
  courseMembershipGroupIds,
  allMembershipGroups,
  showSummary,
  setShowSummary,
  selectedAttempt,
  setSelectedAttempt,
  refetchLearnerCategories = null,
}) => {
  const { currentUser, hasRole } = useAuth()
  const roles = ['ADMIN', 'OWNER']
  const isAdmin = hasRole(roles)

  //include isloading state for the reset mutation
  const [resetCourseTracking, { loading }] = useMutation(
    RESET_LEARNER_COURSE_TRACKING,
    {
      onCompleted: async () => {
        setSelectedAttempt(0)
        setShowSummary(false)
        if (refetchLearnerCategories) {
          await refetchLearnerCategories()
        }
      },
    },
  )

  const [courseData, setCourseData] = useState(null)
  const [sortedCourseData, setSortedCourseData] = useState(courseData)

  useEffect(() => {
    setCourseData(course)
  }, [course])

  useEffect(() => {
    if (courseData?.reporting?.attempts) {
      const courseDataCopy = JSON.parse(JSON.stringify(courseData))

      courseDataCopy.reporting.attempts.sort(
        (a, b) =>
          dayjs(a.relatedTracking.createdAt).unix() -
          dayjs(b.relatedTracking.createdAt).unix(),
      )

      setSortedCourseData(courseDataCopy)
    }
  }, [courseData])

  const currentCourseTrackingId =
    sortedCourseData?.reporting?.attempts?.[selectedAttempt]?.relatedTracking
      ?.id

  const taskStatuses = sortedCourseData?.reporting?.attempts?.[
    selectedAttempt
  ]?.tasks?.map(({ learnerTaskId, type, attempt }) => {
    return {
      taskId: learnerTaskId,
      type,
      status: attempt.status || null,
    }
  })

  //iterate go through the selected coursetrackings, and then or each of the tasks associated, get the types of tasks, and map them to their associated tracking status

  //use the structure of type: {tasks, total, completed}

  const video = {
    tasks: 0,
    total: 0,
    completed: 0,
  }

  const text = { tasks: 0, total: 0, completed: 0 }

  const quiz = { tasks: 0, total: 0, completed: 0 }

  taskStatuses?.map((taskStatus) => {
    if (taskStatus.type === 'video') {
      video.tasks += 1
      if (taskStatus.status) {
        video.total += 1
        if (taskStatus.status === 'COMPLETED') {
          video.completed += 1
        }
      }
    } else if (taskStatus.type === 'text') {
      text.tasks += 1
      if (taskStatus.status) {
        text.total += 1
        if (taskStatus.status === 'COMPLETED') {
          text.completed += 1
        }
      }
    } else if (taskStatus.type === 'quiz') {
      quiz.tasks += 1
      if (taskStatus.status) {
        quiz.total += 1
        if (taskStatus.status === 'COMPLETED') {
          quiz.completed += 1
        }
      }
    }

    return null
  })

  const membershipGroupsStr = allMembershipGroups
    .filter((membershipGroup) =>
      courseMembershipGroupIds.includes(membershipGroup.id),
    )
    .reduce(
      (acc, membershipGroup) =>
        (acc +=
          acc !== '' ? ', ' + membershipGroup.name : membershipGroup.name),
      '',
    )

  const resetProgress = () => {
    resetCourseTracking({
      variables: {
        id: currentCourseTrackingId,
      },
    })
  }

  const {
    createdAt = new Date(),
    updatedAt = new Date(),
    status = 'IN_PROGRESS' as LearnerAttemptTrackingStatus,
  } = sortedCourseData?.reporting?.attempts?.[selectedAttempt]
    ?.relatedTracking || {}

  const percentage =
    sortedCourseData?.reporting?.attempts?.[selectedAttempt]?.percentage

  const { trackEvent } = useAnalytics()

  return (
    <Modal
      onClose={() => {
        setShowSummary(!showSummary)
        setSelectedAttempt(0)
      }}
      open={showSummary}
      title={sortedCourseData?.name}
      className="z-50 h-[646px] max-w-4xl"
      childClassName=""
      dialogClassName="p-0"
      disablePortal={false}
    >
      <div className="flex flex-col">
        <div className="flex min-h-[518px]">
          <div className="flex w-1/2 flex-col px-6 py-3">
            {sortedCourseData?.heroUrl ? (
              <img
                className="mb-6 h-48 w-full rounded-md object-cover hover:z-50"
                src={sortedCourseData?.heroUrl}
                alt={sortedCourseData?.name}
              />
            ) : (
              <Placeholder
                className="mb-6 min-h-48 rounded-md bg-gray-200"
                image
              />
            )}
            <div className="mb-6 flex h-10">
              <Button
                className="min-w-0 rounded-r-none"
                variant="outlined"
                fullWidth={false}
                disabled={selectedAttempt === 0}
                onClick={() =>
                  selectedAttempt > 0 && setSelectedAttempt(selectedAttempt - 1)
                }
              >
                <span className="sr-only">Previous Attempt</span>
                <ChevronLeftIcon className="h-5 w-5" />
              </Button>
              <div className="flex h-10 w-full flex-col justify-center border border-indigo-500 bg-indigo-50 text-center text-indigo-600">
                Attempt {selectedAttempt + 1}
                {selectedAttempt ===
                  sortedCourseData?.reporting?.attempts?.length - 1 &&
                  ' - Current'}
              </div>
              <Button
                className="min-w-0 rounded-l-none"
                variant="outlined"
                fullWidth={false}
                disabled={
                  selectedAttempt >=
                  sortedCourseData?.reporting?.attempts?.length - 1
                }
                onClick={() =>
                  selectedAttempt <
                    sortedCourseData?.reporting?.attempts?.length - 1 &&
                  setSelectedAttempt(selectedAttempt + 1)
                }
              >
                <span className="sr-only">Next Attempt</span>

                <ChevronRightIcon className="h-5 w-5" />
              </Button>
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <div className="flex flex-1 text-sm text-gray-500">
                  Start Date
                </div>
                <div className="flex flex-1 text-sm text-gray-500">
                  End Date
                </div>
                <div className="flex flex-1 text-sm text-gray-500">Group</div>
              </div>
              <div className="flex">
                <div className="flex flex-1 text-sm text-gray-900">
                  {new Date(createdAt).getDate() +
                    '/' +
                    (new Date(createdAt).getMonth() + 1) +
                    '/' +
                    new Date(createdAt).getFullYear().toString().slice(-2)}
                </div>
                <div className="flex flex-1 text-sm text-gray-900">
                  {status === ('COMPLETED' as LearnerAttemptTrackingStatus)
                    ? new Date(updatedAt).getDate() +
                      '/' +
                      (new Date(updatedAt).getMonth() + 1) +
                      '/' +
                      new Date(updatedAt).getFullYear().toString().slice(-2)
                    : '-'}
                </div>
                <div className="flex flex-1 text-sm text-gray-900">
                  {membershipGroupsStr}
                </div>
              </div>
            </div>
            <div className="mb-auto mt-8 flex justify-between">
              <div className="flex w-full flex-col rounded bg-gray-50 p-2">
                <div className="">
                  <PuzzlePieceIcon className="mx-auto h-10 w-10 text-gray-200" />
                </div>
                <div className="mx-auto text-base text-gray-900">
                  {quiz.tasks > 0
                    ? Math.round((quiz.completed / quiz.tasks) * 100 * 100) /
                        100 +
                      '%'
                    : 'N/A'}
                </div>
                <div className="mx-auto text-[10px] text-indigo-600">
                  QUIZ SCORE
                </div>
              </div>
              <div className="mx-4 flex w-full flex-col rounded bg-gray-50 p-2">
                <div className="">
                  <VideoCameraIcon className="mx-auto h-10 w-10 text-gray-200" />
                </div>
                <div className="mx-auto text-base text-gray-900">
                  {video.tasks > 0
                    ? Math.round((video.completed / video.tasks) * 100 * 100) /
                        100 +
                      '%'
                    : 'N/A'}
                </div>
                <div className="mx-auto text-[10px] text-indigo-600">
                  VIDEOS
                </div>
              </div>
              <div className="flex w-full flex-col rounded bg-gray-50 p-2">
                <div className="">
                  <DocumentTextIcon className="mx-auto h-10 w-10 text-gray-200" />
                </div>
                <div className="mx-auto text-base text-gray-900">
                  {text.tasks > 0
                    ? Math.round((text.completed / text.tasks) * 100 * 100) /
                        100 +
                      '%'
                    : 'N/A'}
                </div>
                <div className="mx-auto text-[10px] text-indigo-600">TEXT</div>
              </div>
            </div>
          </div>
          <div className="flex max-h-[calc(100vh-200px)] w-1/2 flex-col overflow-y-auto border-l border-gray-200 bg-gray-50 px-6">
            <div className="flex justify-between border-b pb-1.5 pt-2">
              <div className="my-auto flex text-base font-medium text-gray-900">
                Lesson Completion - Attempt {selectedAttempt + 1}
              </div>
              <div className="flex">
                {!currentUser.isClientAlias &&
                  (percentage < 100 ? ( //if there are any potential in progress tasks
                    <CircularProgressWithLabel
                      text={percentage === 0 ? 'start' : null}
                      value={percentage}
                      size={'48px'}
                    />
                  ) : (
                    // if all the tasks have been completed
                    <CheckCircleIcon className={'h-12 w-12 text-green-500'} />
                  ))}
              </div>
            </div>
            <div>
              {sortedCourseData?.learnerActivities?.map((activity) => (
                <CourseChapterCard
                  key={activity.id}
                  chapter={activity && activity}
                  setTask={() => {}}
                  selectedTask={0}
                  taskStatuses={taskStatuses}
                  isSelected={false}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex h-16 justify-between bg-gray-100 px-6 py-3">
          {isAdmin &&
            selectedAttempt ===
              sortedCourseData?.reporting?.attempts?.length - 1 && (
              <Button
                id="learner-summary-reset-activity"
                buttonDataTestId="learner-summary-reset-activity"
                className="flex h-full w-36 items-center rounded bg-red-600 p-3 text-sm text-white"
                onClick={() => {
                  resetProgress()
                  trackEvent('People', 'reset activity', {
                    selectedCourse: sortedCourseData.name,
                  })
                }}
                loading={loading}
              >
                Reset Activity
                <ArrowPathIcon className="h-5 w-5" />
              </Button>
            )}
          <Button
            className="ml-auto flex h-full w-16 items-center rounded bg-indigo-600 p-3 text-sm text-white"
            onClick={() => {
              setShowSummary(!showSummary)
              setSelectedAttempt(0)
              trackEvent('People', 'close summary modal', {
                selectedCourse: sortedCourseData.name,
              })
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default LearnerSummary
